import config from '@/lib/config'

export default class Article {
    private article: ArticleType
    private assetURL: string

    constructor(article: ArticleType) {
        this.article = article
        this.assetURL = config.assetURL
    }

    get slug(): string {
        return this.article.slug
    }

    get id(): number | string {
        return this.article.id
    }

    get title(): string {
        return this.article.title
    }

    get excerpt(): string {
        return this.article.excerpt
    }

    get categories(): CategoryType[] {
        return this.article?.categories ?? []
    }

    get contributors(): ContributorType[] {
        return this.article.contributors ?? []
    }

    // get related_fixtures(): CategoryType[] {
    //     return this.article.attributes.fixtures?.data ?? [];
    // }

    // get related_players(): CategoryType[] {
    //     return this.article.attributes.players?.data ?? [];
    // }

    get published_at(): string | Date {
        const date = new Date(this.article.publishedAt)

        return new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(date)
    }

    get body(): string {
        return this.article.body.replace(
            /(<img\s[^>]*src=")https:\/\/www\.brfcs\.com\/cms([^"]*)(")/,
            '$1' + this.assetURL + `$2$3`,
        )
    }

    getImagePath(size: string): string {
        if (this.assetURL + this.article.image?.formats[size]) {
            return this.assetURL + this.article.image?.formats[size]?.url
        } else {
            return ''
        }
    }

    getImageWidth(size: string): string {
        return this.article.image?.formats[size].width
    }

    srcSet(): string {
        const srcSet = ['thumbnail', 'small', 'medium', 'large']
            .map(size => {
                return `${this.getImagePath(size)} ${this.getImageWidth(size)}w`
            })
            .join(', ')

        return srcSet
    }
}
